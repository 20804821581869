<template src="./ChangePassword.htm">
 


</template>

<script>
import { mapState } from 'vuex';
import Form from '@/utils/form'
import SubHeader from '@/layouts/sub_header/Subheader.vue';

export default {
  name: "Change_Password",
  components: {
      'sub-header': SubHeader,
  },
  data: () => ({
    valid: true,
    name: '',
    overlay: true,
    show1: false,
    show2: false,
    busy: false,
    new_pass: '',
    confirm_pass: '',
    is_admin: '',
    rules: {
      required: value => !!value || 'Required.',
      min: value => value.length >= 8 || 'Min 8 characters',
      emailMatch: () => (`The email and password you entered don't match`),
    },
  }),
  computed: {
    ...mapState({
        user: state => state.auth.user,
        isAuthenticated: state => state.auth.isAuthenticated,
    }),
    passwordConfirmationRule() {
        return () => (this.new_pass === this.confirm_pass) || 'Password do not match'
    },
  },
  methods: {
    resetValidation (){
      this.$refs.addperson_Form.resetValidation()
    },

    submit () {
      if(this.$refs.change_form.validate()){

         this.$api
            .post(`/auth/change-password/`, { password: this.new_pass, email: this.user.email })
            .then(res => {
                this.$toast.open({
                    message:  'Successfully Saved!',
                    type: 'success',
                    position: 'bottom'
                })
            })
            .catch(err => {
                this.$toast.open({
                    message:  err.response.data.msg.message,
                    type: 'error',
                    position: 'bottom'
                })
            })
      }
    },
  },

  created(){
      this.overlay = true;
  },
  mounted() {
    this.overlay = false;
    this.name = this.user.name;
    // this.is_admin = this.user.is_admin;
    this.is_admin = (this.user.is_admin==1 ? true : false);

    if (!this.isAuthenticated) {
      this.$router.replace({ name: 'auth.login' })
    }
  },
}
 </script>